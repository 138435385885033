import { useAuth } from "@/context/auth/hooks";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FunctionComponent, useContext } from "react";
import UserContext from "@/context/user";
import { KUMU_URLS } from "@/utils/urls/kumu";
import { classNames } from "@/utils/classNames";
import useMediaQuery, { desktop } from "@/utils/hooks/useMediaQuery";

const LogOut: FunctionComponent = ({}) => {
  const { logOut } = useAuth();
  const router = useRouter();
  const { t } = useTranslation();
  const { resetUser } = useContext(UserContext);

  const isDesktop = useMediaQuery(desktop);

  const handleLogOut = async () => {
    localStorage.removeItem("mode");
    await logOut().then(() => {
      resetUser();
      router.push(KUMU_URLS.PAGES.B2C.FLOW.LOGIN);
    });
  };

  return (
    <>
      <li
        className={classNames(
          isDesktop
            ? "h-9 hover:bg-[#40b76a] hover:rounded-[8px] font-bold  active:bg-[#40b76a] text-black text-[13px] p-[8px_20px_8px_16px] cursor-pointer z-999"
            : "text-graphite block py-4 px-4 rounded-md text-base desktop:min-w-[192px] relative before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-light-green-leaf after:absolute after:top-0 after:left-0 after:h-full after:w-full after:bg-light-green-leaf before:translate-x-[-100%] after:translate-x-[100%] group-hover:before:translate-x-0 group-hover:after:translate-x-0 before:opacity-100 after:opacity-0 group-hover:before:opacity-0 group-hover:after:opacity-100 after:[transition:transform_200ms_ease,_opacity_100ms_ease_190ms] before:[transition:transform_200ms_ease,_opacity_100ms_ease_190ms] overflow-hidden after:z-[-1] before:z-[-1]"
        )}
        onClick={handleLogOut}
      >
        {t("dashboard:header.dropdownMenu.logOut")}
      </li>
    </>
  );
};

export default LogOut;
