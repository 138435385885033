import { GetIcon } from "@/components/common/icon";
import { Link } from "@/components/common/link";
import { classNames } from "@/utils/classNames";
import { desktop } from "@/utils/hooks/useMediaQuery";
import { KUMU_URLS } from "@/utils/urls/kumu";
import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { FunctionComponent } from "react";
import LogOut from "../kumu-logout";
import { sendGAEvent } from "@next/third-parties/google";

type Props = {
  alwaysVisible?: boolean;
  dropDownMode?: boolean;
  links: { id: string; href: string; label: string }[];
  homeLink: string;

  searchBar?: React.ReactElement;
};

const MainNavigationWrapper: FunctionComponent<Props> = ({
  alwaysVisible,
  dropDownMode,
  links,
  homeLink,
  searchBar,
}) => {
  const isDesktop = useMediaQuery(desktop);
  const router = useRouter();

  return (
    <nav
      className={classNames(
        " min-h-screen min-w-[272px] desktop:bg-transparent",
        alwaysVisible ? "" : "hidden desktop:flex desktop:flex-col",
        isDesktop ? "bg-[#F1F1F1]" : "bg-[#C9D4CA]"
      )}
    >
      <div
        className={
          "px-4 desktop:px-10 pt-[16px] min-w-[272px] desktop:pt-[40px] pb-1  flex flex-col left-0 fixed min-h-full justify-between w-full desktop:w-auto bg-[url('/images/kumu/sidebar-background.webp')] bg-cover"
        }
      >
        <div>
          {!dropDownMode ? (
            <div className="mb-[40px] px-4 ">
              <Link className="cursor-pointer" href={homeLink}>
                <GetIcon
                  iconName={isDesktop ? "kumu-desktop-icon" : "kumu-icon"}
                  className="fill-black"
                />
              </Link>
            </div>
          ) : null}
          {searchBar && <div className="my-4">{searchBar}</div>}
          {links.map((link) => (
            <div
              key={link.id}
              className="relative group overflow-hidden rounded-md mb-3"
            >
              <div className="relative">
                <span
                  className={classNames(
                    "top-6 left-4 bg-black w-2 h-2 absolute rounded-full z-40",
                    router.pathname === link.href ? "flex" : "hidden"
                  )}
                ></span>
                <Link
                  className="text-graphite block py-4 px-4 rounded-md text-base desktop:min-w-[192px] relative before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-light-green-leaf after:absolute after:top-0 after:left-0 after:h-full after:w-full after:bg-light-green-leaf before:translate-x-[-100%] after:translate-x-[100%] group-hover:before:translate-x-0 group-hover:after:translate-x-0 before:opacity-100 after:opacity-0 group-hover:before:opacity-0 group-hover:after:opacity-100 after:[transition:transform_200ms_ease,_opacity_100ms_ease_190ms] before:[transition:transform_200ms_ease,_opacity_100ms_ease_190ms] overflow-hidden after:z-[-1] before:z-[-1]"
                  href={link.href}
                  activeClassName="bg-[#E1F0DB] font-bold pl-8"
                  activePathname={link.href}
                >
                  {link.label}
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className=" group overflow-hidden rounded-md  relative">
            {!isDesktop && <LogOut />}
          </div>
          <div className=" group overflow-hidden rounded-md mb-20 relative">
            <Link
              className="text-graphite block py-4  px-4 rounded-md text-base desktop:min-w-[192px] relative before:absolute before:top-0 before:left-0 before:h-full before:w-full before:bg-light-green-leaf after:absolute after:top-0 after:left-0 after:h-full after:w-full after:bg-light-green-leaf before:translate-x-[-100%] after:translate-x-[100%] group-hover:before:translate-x-0 group-hover:after:translate-x-0 before:opacity-100 after:opacity-0 group-hover:before:opacity-0 group-hover:after:opacity-100 after:[transition:transform_200ms_ease,_opacity_100ms_ease_190ms] before:[transition:transform_200ms_ease,_opacity_100ms_ease_190ms] overflow-hidden after:z-[-1] before:z-[-1]"
              href={KUMU_URLS.RESOURCES.HELP.HELPURL}
              target="blank"
              onClick={() =>
                sendGAEvent({ event: "helpButton", value: "Help button" })
              }
              activeClassName="bg-[#E1F0DB] list-item list-inside font-bold"
              activePathname={"https://support.kumu.com"}
            >
              {"Help"}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigationWrapper;
