import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Input } from "@/components/common/input";
import { GetIcon } from "@/components/common/icon";
import { classNames } from "@/utils/classNames";
import useMediaQuery, { desktop } from "@/utils/hooks/useMediaQuery";

type Props = {
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearch: (value: string | undefined) => void;
  onReset?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  placeholder?: string;
  dark: boolean;
  disabled?: boolean;
};

const KumuSearchInput: FunctionComponent<Props> = ({
  value,
  onSearch,
  onChange,
  onReset,
  onFocus,
  onBlur,
  className,
  placeholder,
  dark,
  disabled = false,
}) => {
  const [isTyping, setIsTyping] = useState(value !== "");
  const ref = useRef<HTMLInputElement>(null);

  const isDesktop = useMediaQuery(desktop);
  const handleDelete = () => {
    if (ref.current != null && ref.current.value != "") {
      ref.current.value = "";
    }
    setIsTyping(false);
    onReset?.();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    if (ref?.current?.value === "") {
      onReset?.();
    } else {
      setIsTyping(true);
    }
  };

  const handleFocus = () => {
    onFocus?.();
    setIsTyping(true);
  };

  const handleBlur = () => {
    if (!value) setIsTyping(false);
    if (onBlur) onBlur();
  };

  const handleSerch = (value: string | undefined) => {
    onSearch?.(value);
    handleBlur();
    if (onBlur) onBlur();
  };

  useEffect(() => {
    if (value !== "") setIsTyping(true);
    else setIsTyping(false);
  }, [value]);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") handleSerch(value);
    return;
  };

  return (
    <div className={classNames("relative flex items-center", className)}>
      <GetIcon
        className={classNames("absolute left-2 top-4")}
        iconName="search-v2"
      />

      {isTyping && (
        <>
          <div>
            <div
              onClick={() => {
                !disabled && handleSerch(value);
              }}
              className={classNames("z-10")}
            >
              {disabled ? (
                <GetIcon
                  className="absolute right-2 top-5 w-5 h-5 animate-spin fill-current text-graphite "
                  iconName="loading"
                />
              ) : (
                <GetIcon
                  className="absolute right-2 top-5 w-5 h-5 fill-current origin-bottom cursor-pointer"
                  iconName="kumu-arrow-right"
                />
              )}
            </div>
            <div
              onClick={() => {
                !disabled && handleDelete();
              }}
              className={classNames("z-10", disabled && "opacity-50")}
            >
              <GetIcon
                className="absolute right-10 top-6 w-3 h-3 fill-current opacity-20 origin-bottom cursor-pointer hover:opacity-80 transition-all duration-300"
                iconName="close"
              />
            </div>
          </div>
        </>
      )}

      <Input
        ref={ref}
        modifier={dark ? "dark" : undefined}
        onChange={handleChange}
        placeholder={isTyping ? "" : placeholder}
        withBorder={true}
        disabled={disabled}
        onKeyDown={handleKeyPress}
        className={classNames(
          "!rounded-full h-9 w-full mt-0",
          "px-10",
          "text-[13px] border border-solid border-graphite !focus:outline-none !bg-white",
          disabled && "opacity-50",
          "!border-[#20242133]"
        )}
        onClick={() => setIsTyping(true)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
      />
    </div>
  );
};

export { KumuSearchInput };
