import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";

import MainNavigationWrapper from "@/features/kumu/common/main-navigation-wrapper";
import { KUMU_URLS } from "@/utils/urls/kumu";
import UserContext from "@/context/user";
import { useRouter } from "next/router";

type Props = {
  alwaysVisible?: boolean;
  dropDownMode?: boolean;
  searchBar?: React.ReactElement;
};

const links = (mode: string, user: User) => {
  return !user.id
    ? []
    : mode === "expert"
    ? [
        { id: "home", href: KUMU_URLS.PAGES.B2B.MAIN, label: "Home" },
        {
          id: "your-page",
          href: KUMU_URLS.PAGES.B2B.PROFILE_PREVIEW,
          label: "Profile",
        },
        {
          id: "your-library",
          href: KUMU_URLS.PAGES.B2B.LIBRARY,
          label: "Library",
        },
        {
          id: "posts",
          href: KUMU_URLS.PAGES.B2B.POSTS.MAIN,
          label: "Create",
        },
        {
          id: "audiencie-insights",
          href: KUMU_URLS.PAGES.B2B.AUDIENCE_INSIGHTS,
          label: "Insights",
        },
        {
          id: "inspiration",
          href: KUMU_URLS.PAGES.B2B.INSPIRATION,
          label: "Inspiration",
        },
      ]
    : [
        { id: "home", href: KUMU_URLS.PAGES.B2C.MAIN, label: "Home" },
        {
          id: "memberships",
          href: KUMU_URLS.PAGES.B2C.MEMBERSHIPS.MAIN,
          label: "Subscriptions",
        },
        {
          id: "courses",
          href: KUMU_URLS.PAGES.B2C.COURSES.MAIN,
          label: "Series",
        },
        {
          id: "digital_products",
          href: KUMU_URLS.PAGES.B2C.DIGITAL_PRODUCTS.MAIN,
          label: "Digital products",
        },
      ];
};

const MainLayoutWrapper: FunctionComponent<Props> = ({
  alwaysVisible,
  dropDownMode,
  searchBar,
}) => {
  const { user } = useContext(UserContext);

  const router = useRouter();
  const [mode, setMode] = useState<string>("");

  useEffect(() => {
    let modeLocal = localStorage.getItem("mode");
    modeLocal
      ? setMode(modeLocal)
      : setMode(router.asPath.includes("dashboard") ? "expert" : "member");
  }, [, router]);

  return (
    <MainNavigationWrapper
      alwaysVisible={alwaysVisible}
      searchBar={searchBar}
      dropDownMode={dropDownMode}
      links={links(mode, user)}
      homeLink={
        mode === "expert" ? KUMU_URLS.PAGES.B2B.MAIN : KUMU_URLS.PAGES.B2C.MAIN
      }
    />
  );
};

export default MainLayoutWrapper;
